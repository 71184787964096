.wrapper {
  text-align: center;
  margin: 2rem auto;
  max-width: 80vw;
}

.employees-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .employees-list__filters {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: center;

    .employees-list__filter {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      &:last-child {
        margin-left: auto;
      }

      select,
      input[type="text"] {
        padding: 0.25rem;
        font-size: 1rem;
      }

      input[type="checkbox"] {
        margin-right: 0.25rem;
      }
    }
  }

  .employees-list__cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 1rem;

    .employee-card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 1rem;
      border: 1px solid #ddd;
      border-radius: 0.5rem;
      transition: all 0.3s ease-in-out;
      cursor: pointer;

      &:hover {
        transform: scale(1.02);
        border-color: #2ecc71;
      }

      &__position,
      &__phone {
        font-size: 1.2rem;
        font-weight: bold;
        margin-bottom: 0.5rem;
        text-align: center;
      }

      &__name {
        color: rgb(45, 95, 45);
        font-size: 23px;
      }

      &__status {
        color: red;
        font-weight: bold;
        margin-top: 0.5rem;
      }
    }
  }

  @media (max-width: 768px) {
    .employees-list__cards {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
  }

  @media (max-width: 576px) {
    .employees-list__filters {
      flex-direction: column;

      .employees-list__filter {
        width: 100%;

        select,
        input[type="text"] {
          width: 100%;
        }

        &:last-child {
          margin-left: 0;
          margin-top: 1rem;
        }
      }
    }

    .employees-list__cards {
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }
  }
}

.form {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  margin: 0 auto;
}

.form_group {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.form_input {
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

.form_input:focus {
  outline: none;
  border-color: #2ecc71;
}

.form_label {
  margin-bottom: 5px;
  font-size: 16px;
}

.field-error {
  color: red;
}

.select {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
}

select {
  background-color: white;
  border: 2px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  padding: 10px;
  width: 100%;
  appearance: none;
  outline: none;
  cursor: pointer;
  transition: border-color 0.3s ease;
}

select:focus {
  border-color: #2ecc71;
}

.btn {
  padding: 10px 20px;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  background-color: #4caf50;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover:not(:disabled) {
    background-color: #357a38;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
}

.btn_green {
  background-color: #2ecc71;
}

.btn_blue {
  background-color: #2e53cc;
}

a {
  color: #0077cc;
  text-decoration: none;
  transition: all 0.4s ease;
  font-size: 20px;
  padding: 5px;
  position: relative;
  top: 1rem;

  &:hover {
    text-decoration: none;
    background-color: #005ea2;
    color: #fff;
    border-radius: 5px;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px #0077cc;
  }

  &:active {
    color: #005ea2;
  }
}

@media screen and (max-width: 768px) {
  .form {
    max-width: 100%;
  }
}

.modal {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease-in-out;

  &__content {
    font-size: 20px;
    width: 200px;
    min-width: 200px;
    border-radius: 4px;
    padding: 5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    animation: modalFadeIn 0.3s forwards;
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    background-color: transparent;
    border: none;
    font-size: 2rem;
    line-height: 1;
    padding: 0.5rem;
    cursor: pointer;
  }
}

.modal__content-red {
  background-color: rgb(211, 113, 34);
  font-size: 20px;
  width: 200px;
  min-width: 200px;
  border-radius: 4px;
  padding: 5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  animation: modalFadeIn 0.3s forwards;
}

.modal__content-green {
  background-color: rgb(51, 173, 82);
  font-size: 20px;
  width: 200px;
  min-width: 200px;
  border-radius: 4px;
  padding: 5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  animation: modalFadeIn 0.3s forwards;
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(-50%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh;
  width: 80vw;
  font-size: 2rem;
}

.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #2e53cc;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
